/* eslint-disable */
import { I18n } from '@lyfta/components-i18n'
import { ButtonNew, Flex } from '@lyfta/components-ui'
import React, { useState } from 'react'

import {
  BodyGuideLine,
  InlineLink,
  Subtitle2GuideLine,
  SubtitleGuideLine,
  TitleGuideLine,
} from '../styles'

export default function guideline(handleGuidelineApproved) {
  const [isChecked, setIsChecked] = useState(false)
  const handleFlexClick = () => {
    setIsChecked(prevState => !prevState)
  }
  return (
    <>
      <TitleGuideLine>Lyfta community guidelines</TitleGuideLine>
      <BodyGuideLine>
        Welcome to the Lyfta community. Whether you're creating content for the
        Lyfta platform, participating in our social media groups, or attending
        Lyfta events, these guidelines outline Lyfta's community guidelines.{' '}
      </BodyGuideLine>
      <BodyGuideLine>
        By engaging with our community, you agree to uphold these standards and
        contribute to an inclusive and inspiring environment for everyone.
      </BodyGuideLine>
      <SubtitleGuideLine>1. Content Creation & Sharing</SubtitleGuideLine>
      <Subtitle2GuideLine>Quality & Accuracy</Subtitle2GuideLine>
      <BodyGuideLine>
        Content should be factually accurate, up-to-date, and aligned with
        relevant educational standards and learning objectives. The content you
        share should provide value and insight to fellow educators.
      </BodyGuideLine>
      <Subtitle2GuideLine>Appropriateness</Subtitle2GuideLine>
      <BodyGuideLine>
        All content must suit the intended audience and be free from offensive,
        discriminatory, or harmful language and imagery. We must respect the
        diversity of our community by choosing words and content that foster
        inclusivity and understanding. You can find BBFC ratings for every Lyfta
        film on the film's preview page, and also filter for BBFC ratings in
        search. Furthermore, all Lyfta-created lesson plans are tagged with the
        key stages they are intended to be used with.
      </BodyGuideLine>
      <Subtitle2GuideLine>Originality & Attribution</Subtitle2GuideLine>
      <BodyGuideLine>
        If you include external resources or inspirations in your content,
        ensure you have the right to use them and provide appropriate
        attribution. Integrity in creation and sharing is crucial to maintaining
        trust within our community. Please ensure you always abide by relevant
        copyright laws. By publishing your creation publicly, you grant Lyfta
        the right to display and promote your work to other users on the
        platform. Lyfta retains ownership of any content you contribute to the
        collection.
      </BodyGuideLine>
      <SubtitleGuideLine>2. Behaviour & Interaction</SubtitleGuideLine>
      <Subtitle2GuideLine>Respect & Inclusivity</Subtitle2GuideLine>
      <BodyGuideLine>
        Treat all community members with respect, kindness, and dignity, valuing
        the diversity of backgrounds, perspectives, and experiences within the
        community. Discrimination, harassment, and hateful behaviour will not be
        tolerated.
      </BodyGuideLine>
      <Subtitle2GuideLine>Constructive Engagement</Subtitle2GuideLine>
      <BodyGuideLine>
        Participate in discussions and interactions constructively. Offer kind
        feedback, share ideas, and ask questions in a manner that encourages
        positive dialogue and mutual learning. Avoid behaviours that disrupt the
        community's harmony and learning atmosphere.
      </BodyGuideLine>
      <Subtitle2GuideLine>Privacy & Safety</Subtitle2GuideLine>
      <BodyGuideLine>
        Respect the privacy and personal information of others. Do not share
        confidential or private information without explicit consent. Engage in
        practices that ensure the community's and its members' safety and
        security. All interactions outside of the Lyfta platform should also
        adhere to the rules and guidelines of that platform.{' '}
      </BodyGuideLine>
      <SubtitleGuideLine>3. Compliance & Enforcement</SubtitleGuideLine>
      <Subtitle2GuideLine>Adherence to Guidelines</Subtitle2GuideLine>
      <BodyGuideLine>
        By participating in the Lyfta community, you agree to adhere to these
        guidelines. Lyfta reserves the right to moderate content and
        interactions, including removing content or suspending accounts that
        violate these principles.
      </BodyGuideLine>
      <Subtitle2GuideLine>Reporting & Support</Subtitle2GuideLine>
      <BodyGuideLine>
        If you encounter content or behaviour that violates these guidelines,
        please report it to the Lyfta team. We are committed to maintaining a
        supportive environment and will take appropriate action to address
        issues. To do this, either use the 'report content' links or notify us
        via the blue chat box or by email:{' '}
        <InlineLink href="mailto:info@lyfta.com">info@lyfta.com.</InlineLink>
      </BodyGuideLine>
      <BodyGuideLine>
        We are committed to constantly improving our community experience, and
        as our community evolves, so will our guidelines. Your feedback is
        invaluable on this journey. Please share your suggestions, ideas, and
        concerns via this{' '}
        <InlineLink
          ml={2}
          target="_blank"
          href="https://form.asana.com/?k=0HdY0Y34s8uW_XjnhO-j5A&d=1133701671147737"
        >
          form
        </InlineLink>
        .{' '}
      </BodyGuideLine>
      <BodyGuideLine>
        {' '}
        By joining the Lyfta community, you're contributing to a space that
        fosters learning, understanding, and respect. Let's ensure our community
        remains a safe, welcoming, and vibrant place for everyone. Lyfta
        reserves the right to remove any content.{' '}
      </BodyGuideLine>
      <BodyGuideLine>
        By checking the box below, you confirm you have read and understood
        these Terms & Conditions.
      </BodyGuideLine>
      <Flex
        mt={4}
        onClick={handleFlexClick}
        style={{ cursor: 'pointer', alignItems: 'flex-start' }}
      >
        <Flex mr={3} mt={2}>
          <input type="checkbox" checked={isChecked} readOnly />
        </Flex>{' '}
        <Flex>
          {' '}
          I have read and understood the Terms & Conditions set out in the
          community guidelines.
        </Flex>
      </Flex>
      <Flex mt={4} mb={2} justifyContent="center">
        <ButtonNew
          disabled={!isChecked}
          onClick={() => handleGuidelineApproved()}
          type="task"
        >
          <I18n text="actions.publishCollection" />
        </ButtonNew>
      </Flex>
    </>
  )
}
