import {
  css,
  styled,
  themeGet,
  walsheimAndFallbackFontFamily,
} from '@lyfta/components-theme'
import { merge } from 'lodash'

import { Button as BaseButton } from '../../BaseComponents'
import { reactEvent } from '../../Services/Utils'
import { getDeleteStyle } from './helpers/getDeleteStyle'
import { getDisabledStyle } from './helpers/getDisabledStyle'
import { getSubtypeStyle } from './helpers/getSubtypeStyle'

const getButtonType = p => {
  let styleKey
  if (p.type && p.type !== 'button') {
    styleKey = `buttonType.${p.type}`
  } else {
    styleKey = `buttonType.cta`
  }

  styleKey = `${styleKey}.${p.secondary ? 'secondary' : 'primary'}`

  const buttonType = themeGet(styleKey)(p) || themeGet(`buttonType.cta.primary`)
  return buttonType
}

const handleGoogleEvent = event => {
  if (event && event.action) {
    reactEvent({ action: event.action, params: event.params })
  }
}

export const ButtonNew = styled(BaseButton)
  .withConfig({
    shouldForwardProp: prop => !['default'].includes(prop),
  })
  .attrs(p => {
    const buttonDiv = !p.buttonDiv ? '' : { buttonDiv: true }
    const buttonType = getButtonType(p)
    const deleteStyling = getDeleteStyle(p)
    const subtypeStyling = getSubtypeStyle(p)

    const uppercase = p.uppercase
      ? {
          default: { textTransform: 'uppercase' },
        }
      : ''

    const disabled = getDisabledStyle(p, buttonType)

    // Order in which css properties are applied. From low priority to high.
    // 1- Standard CSS properties for btn
    // 2- ButtonsNew.js on themes/Main
    // 3- Properties added to the ButtonNew component
    // 4- disabled and uppercase.
    // 5- pure CSS.

    const originalOnClick = p.onClick
    const googleEventHandler = p.googleEvent
      ? () => handleGoogleEvent(p.googleEvent)
      : null

    return merge(
      {
        fontFamily: `${walsheimAndFallbackFontFamily}`,
        fontWeight: '700',
      },
      buttonDiv,
      buttonType,
      deleteStyling,
      subtypeStyling,
      p,
      uppercase,
      disabled,
      {
        onClick: e => {
          if (googleEventHandler) {
            googleEventHandler()
          }
          if (originalOnClick) {
            originalOnClick(e)
          }
        },
      },
    )
  })`
  position: relative;
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  letter-spacing: 1.5px;
  white-space: nowrap;
  align-items: center;
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  ${p => {
    return css`
      ${p.default};
      svg * {
        fill: ${p?.default?.fill};
      }
      &:focus-visible:not(:active) {
        ${!p.disabled && p.focusVisible};

        svg * {
          fill: ${!p.disabled && p?.hover?.fill};
        }
      }

      &:hover:not(:focus-visible):not(:active) {
        ${!p.disabled && p.hover};

        svg * {
          fill: ${!p.disabled && p?.hover?.fill};
        }
      }
      &:active {
        ${!p.disabled && p.pressed};

        svg * {
          fill: ${!p.disabled && p?.pressed?.fill};
        }
      }
    `
  }}
`
