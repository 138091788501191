import { isAdminApp, isTeacherApp } from '@lyfta/components-config'
import { ageRanges, lessonPlanTypes, subjects } from '@lyfta/components-data'
import { loadSubjects } from '@lyfta/components-data/src/Store/Actions/subjects'
import { getLocale } from '@lyfta/components-data/src/Store/Selectors/persist'
import { getAuthorId } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { I18n, getTranslation, globalFormatDate } from '@lyfta/components-i18n'
import {
  AnimatedInputNew,
  AnimatedTextAreaNew,
  Balloon,
  Bin,
  ButtonNew,
  ContentWarning,
  Duplicate,
  Edit,
  Flex,
  Icon,
  InfoCount,
  Information,
  Modal,
  Plus,
  ReactSelectFormMulti,
  Save,
  Share,
} from '@lyfta/components-ui'
import { get, take } from 'lodash'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
/* eslint-disable */
import { useHistory } from 'react-router-dom'

import { updateContent } from '../../../Store/Actions/contents'
import Favorite from '../../Favorite'
import {
  AuthorContainer,
  FileContainer,
  ImageWrapper,
  SubscribeContainer,
  TextColor,
  ThumbImage,
  TitleModal,
  TitleViewOnly,
} from '../styles'
import guideline from './guideline'

const myForm = props => {
  const {
    dirty,
    handleSubmit,
    values,
    viewOnly,
    actionsForm: {
      handleShareModal,
      handleDuplicate,
      handleEdit,
      handleDelete,
      handleInformationModal,
      setShowLyftaContentModal,
      paths,
    },
  } = props

  useEffect(() => {
    dispatch(loadSubjects())
  }, [])

  useEffect(() => {
    if (values && values.state) {
      setState(values.state)
    }
  }, [values])

  const submit = () => {
    // Only try to submit if something on the form has changed
    if (dirty) {
      handleSubmit()
    }
  }
  const [showUnpublishModal, setShowUnpublishModal] = useState(false)

  const [state, setState] = useState('')

  const [isOpen, setIsOpen] = useState(false)
  const [isGuidelineOpen, setIsGuidelineOpen] = useState(false)

  const [isOpenContentModal, setIsOpenContentModal] = useState(false)

  const viewerAuthorId = useSelector(getAuthorId)
  const locale = useSelector(getLocale)
  const dispatch = useDispatch()
  const history = useHistory()
  const thumbUrl = get(values, 'posterUrl')
  const totalContent = get(values, 'totalContent')
  const authorId = get(values, 'authorId')
  const createdByLyfta = get(values, 'createdByLyfta')
  const authorName = get(values, 'authorName')
  const createdAt = get(values, 'createdAt')
  const collectionId = get(values, 'id')

  // Collections fields to force teacher to fill them
  const collectionName = get(values, 'name')
  const collectionDescription = get(values, 'description')
  const collectionAgeRanges = get(values, 'ageRanges')
  const collectionAgeRangesLabel = get(values, 'ageRangesLabel')
  const favorites =
    get(values, 'favorites') && get(values, 'favorites').length === 0
      ? false
      : get(values, 'favorites')
  // const collectionSubjects = get(values, 'subjects')
  const collectionKeywords = get(values, 'keywords')
  const formSelectOptions = { ageRanges, lessonPlanTypes, subjects }

  const contentId = useMemo(() => {
    return get(values, 'contentId')
  }, [values?.contentId])

  const isOwner = String(viewerAuthorId) === authorId

  // In admin app, the admin alway can edit the collection
  const isNoOwnerAndNoAdminApp = !isOwner && !isAdminApp
  const isOwnerOrAdminApp = isOwner || isAdminApp

  const postText = createdByLyfta
    ? ''
    : `(ID: ${take(collectionId, 4).join('')})`

  const preText = collectionAgeRangesLabel

  const authorNameLabel = isOwner
    ? `${getTranslation('collections.you')} - ${globalFormatDate(createdAt)}`
    : `${authorName} - ${globalFormatDate(createdAt)}`

  const onClickPublish = () => {
    if (state === 'draft') publishUnpublishCollection()
    else unpublishCollection()
  }

  const publishUnpublishCollection = () => {
    if (
      (totalContent < 5 ||
        !collectionName ||
        !collectionDescription ||
        collectionAgeRanges.length === 0 ||
        !collectionKeywords) &&
      state === 'draft'
    )
      setIsOpenContentModal(true)
    else {
      const newState = state === 'draft' ? 'published' : 'draft'

      if (state === 'draft') setIsGuidelineOpen(true)
      else {
        const updatedContentObject = {
          id: values.contentId,
          type: 'collection',
          state: newState,
          availableLocales: [locale],
          short_description_en: values.description,
        }
        dispatch(updateContent({ ...updatedContentObject }))

        setTimeout(() => {
          location.reload()
        }, 500)
      }
    }
  }

  const unpublishCollection = () => {
    setShowUnpublishModal(!showUnpublishModal)
  }

  const handleGuidelineApproved = () => {
    setIsGuidelineOpen(false)

    const newState = state === 'draft' ? 'published' : 'draft'
    const updatedContentObject = {
      id: values.contentId,
      type: 'collection',
      state: newState,
      availableLocales: [locale],
      short_description_en: values.description,
    }
    dispatch(updateContent({ ...updatedContentObject }))

    if (state === 'draft') setIsOpen(true)
    else {
      setTimeout(() => {
        location.reload()
      }, 500)
    }
  }

  const onClickAddContent = () => {
    setIsOpenContentModal(false)

    setShowLyftaContentModal(true)
  }

  const renderNoViewOnlyButtons = () => {
    return (
      <Flex justifyContent="left" alignItems="flex-start" flexWrap="wrap">
        {isOwner && (
          <ButtonNew
            id="btn-delete"
            leadingIcon={<Icon glyph={Bin} height={20} width={20} />}
            type="icon"
            mr={2}
            delete
            secondary
            buttonDiv
            onClick={() => handleDelete()}
          />
        )}
      </Flex>
    )
  }

  const renderViewOnlyButtons = () => {
    if (!contentId) {
      return null
    }

    return (
      <Flex justifyContent="left" alignItems="flex-start" flexWrap="wrap">
        {!isOwner && (
          <Favorite
            button
            favoriteProperty={favorites}
            contentId={contentId}
            data-intercom-target="favourite-btn"
            isCollection
          />
        )}

        {isOwner && (
          <ButtonNew
            id="btn-edit"
            leadingIcon={<Icon glyph={Edit} height={20} width={20} />}
            type="icon"
            mr={1}
            secondary
            buttonDiv
            onClick={() => handleEdit()}
          />
        )}
        <ButtonNew
          id="btn-duplicate"
          leadingIcon={<Icon glyph={Duplicate} height={20} width={20} />}
          type="icon"
          mr={1}
          secondary
          buttonDiv
          onClick={() => handleDuplicate()}
        />

        <ButtonNew
          id="btn-share"
          leadingIcon={<Icon glyph={Share} height={20} width={20} />}
          type="icon"
          mr={2}
          secondary
          buttonDiv
          onClick={() => handleShareModal()}
        />
        {isOwner && (
          <ButtonNew
            id="btn-delete"
            leadingIcon={<Icon glyph={Bin} height={20} width={20} />}
            type="icon"
            mr={2}
            delete
            secondary
            buttonDiv
            onClick={() => handleDelete()}
          />
        )}
      </Flex>
    )
  }

  return (
    <Flex width="95%" flexDirection="column">
      <Flex alignItems={viewOnly ? 'flex-start' : 'flex-end'} flexWrap="wrap">
        <Flex my={4} flexWrap="wrap">
          <FileContainer mr={2} mb={3}>
            {thumbUrl ? (
              <ImageWrapper>
                <ThumbImage src={thumbUrl} />
              </ImageWrapper>
            ) : null}
          </FileContainer>
          {!viewOnly && renderNoViewOnlyButtons()}
          {viewOnly && renderViewOnlyButtons()}
        </Flex>
      </Flex>

      <Flex flexDirection="row">
        <AnimatedInputNew
          width="fit-content"
          justifyContent="center"
          data-intercom-target="first-name"
          id="input-first-name"
          label={<I18n text="fields.collectionName" />}
          mb={4}
          name="name"
          description={getTranslation('collections.nameText')}
          viewOnly={viewOnly || isNoOwnerAndNoAdminApp}
          handleBlur={submit}
          viewOnlyProps={{
            hideLabel: true,
            fieldTag: TitleViewOnly,
          }}
          appendText={{ postText, preText }}
          addIcon={
            !isOwner &&
            !createdByLyfta && (
              <Icon
                data-intercom-target="community-collection"
                glyph={Information}
                onClick={() => handleInformationModal('report')}
                ml={2}
                fill="warning_80"
                height={24}
                width={24}
              />
            )
          }
        />
      </Flex>
      {viewOnly && (
        <>
          <Flex>
            {createdByLyfta && (
              <AuthorContainer>
                <I18n
                  i18nKey="collections.createdByLyftaWithDate"
                  options={{
                    createdAt: globalFormatDate(createdAt),
                  }}
                />{' '}
              </AuthorContainer>
            )}
            {!createdByLyfta && (
              <AuthorContainer>
                <Flex flexDirection="column">
                  <I18n
                    i18nKey="collections.createdByTeacher"
                    options={{
                      authorName: authorNameLabel,
                    }}
                  />
                </Flex>
              </AuthorContainer>
            )}
          </Flex>
          <InfoCount
            mb={3}
            type="collections"
            direction="column"
            subscribedCount={values.subscribedCount}
            viewedCount={values.viewedCount}
          />
          {values.contentWarningDescription && (
            <Flex mb={2}>
              <SubscribeContainer color="">
                <Icon glyph={ContentWarning} width={16} height={16} mr={2} />
                {values.contentWarningDescription}
              </SubscribeContainer>
            </Flex>
          )}
        </>
      )}
      <AnimatedTextAreaNew
        allowEmptyField={false}
        description={getTranslation('collections.descriptionText')}
        inputId="text-area-activity-description"
        rows={3}
        label={<I18n i18nKey="fields.collectionDescription" />}
        mb={4}
        name="description"
        viewOnly={viewOnly || isNoOwnerAndNoAdminApp}
        viewOnlyProps={{ fieldTag: TextColor }}
        handleBlur={submit}
      />

      <AnimatedTextAreaNew
        description={getTranslation('collections.teacherNotesText')}
        rows={3}
        label={<I18n i18nKey="collections.teacherNotes" />}
        mb={3}
        name="teacherNotes"
        handleBlur={submit}
        viewOnly={viewOnly || isNoOwnerAndNoAdminApp}
        viewOnlyProps={{ fieldTag: TextColor }}
      />
      {viewOnly && (
        <AnimatedInputNew
          description={getTranslation('collections.subjects')}
          rows={3}
          label={<I18n i18nKey="collections.subjects" />}
          mb={3}
          name="subjects"
          viewOnly={viewOnly || isNoOwnerAndNoAdminApp}
          viewOnlyProps={{ fieldTag: TextColor }}
        />
      )}
      {viewOnly && (
        <AnimatedInputNew
          description={getTranslation('collections.ageRangesDescription')}
          rows={3}
          label={<I18n i18nKey="collections.ageRanges" />}
          mb={3}
          name="ageRanges"
          viewOnly={viewOnly || isNoOwnerAndNoAdminApp}
          viewOnlyProps={{ fieldTag: TextColor }}
        />
      )}
      {viewOnly && (
        <AnimatedInputNew
          description={getTranslation('collections.tags')}
          rows={3}
          label={<I18n i18nKey="collections.tags" />}
          mb={3}
          name="tags"
          viewOnly={viewOnly || isNoOwnerAndNoAdminApp}
          viewOnlyProps={{ fieldTag: TextColor }}
        />
      )}
      <AnimatedInputNew
        rows={3}
        label={<I18n i18nKey="collections.keywords" />}
        mb={3}
        name="keywords"
        description={getTranslation('collections.keywordsDescription')}
        viewOnly={viewOnly || isNoOwnerAndNoAdminApp}
        handleBlur={submit}
        viewOnlyProps={{ fieldTag: TextColor }}
      />
      {!viewOnly && isOwnerOrAdminApp && (
        <ReactSelectFormMulti
          description={getTranslation('collections.ageRangesDescription')}
          label={<I18n i18nKey="content.ageRanges.label" />}
          mb={3}
          name="ageRanges"
          options={formSelectOptions.ageRanges}
          closeMenuOnSelect={false}
        />
      )}
      {!viewOnly && isOwnerOrAdminApp && (
        <ReactSelectFormMulti
          description={getTranslation('collections.subjectsDescription')}
          label={<I18n i18nKey="content.subjects.label" />}
          mb={3}
          name="subjects"
          options={formSelectOptions.subjects}
          closeMenuOnSelect={false}
        />
      )}
      {!viewOnly && isOwnerOrAdminApp && (
        <Flex width={1} mt={3} mb={2} justifyContent="flex-end">
          <ButtonNew
            mr={4}
            leadingIcon={<Icon mr={2} glyph={Save} height={20} width={20} />}
            disabled
            type="task"
          >
            <I18n text="actions.autoSaveOn" />
          </ButtonNew>
          <ButtonNew onClick={() => onClickPublish()} type="task" buttonDiv>
            {state === 'draft' ? (
              <I18n text="actions.publish" />
            ) : (
              <I18n text="actions.unpublish" />
            )}
          </ButtonNew>
        </Flex>
      )}
      <Modal
        isOpen={isOpen}
        maxWidth="450px"
        modalStyle={{
          minHeight: '40%',
        }}
        buttons={{
          okText: (
            <Flex>
              <Icon glyph={Share} mr={2} />
              <I18n text="actions.shareNow" />
            </Flex>
          ),
          cancelText: '',
        }}
        content={{
          title: (
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Icon glyph={Balloon} mr={2} />
              <TitleModal mt={2} textAlign="center" color="secondary_130">
                {' '}
                <I18n text="core.modals.congrats" />
              </TitleModal>
            </Flex>
          ),
          description: [
            'core.modals.publishCollection',
            'core.modals.publishCollection2',
          ],
        }}
        onCallback={() => {
          setIsOpen(false)
          handleShareModal(true)
        }}
        onDismissModal={() => {
          setIsOpen(false)
          if (isTeacherApp)
            history.push(paths.favorite.collectionPreview(collectionId))
        }}
      />
      <Modal
        isOpen={isOpenContentModal}
        maxWidth="500px"
        modalStyle={{
          minHeight: '40%',
        }}
        buttons={{
          okText: (
            <Flex
              onClick={() => onClickAddContent()}
              justifyContent="center"
              alignItem="center"
            >
              <Icon glyph={Plus} mr={2} height={16} width={16} />
              <I18n text="actions.addContent" />
            </Flex>
          ),
          cancelText: (
            <Flex justifyContent="center" alignItem="center">
              <Icon glyph={Save} mr={2} height={16} width={16} />
              <I18n text="actions.saveDraft" />
            </Flex>
          ),
        }}
        content={{
          title: (
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <TitleModal mt={2}>
                {' '}
                <I18n text="core.modals.shareModal.titleContentModal" />
              </TitleModal>
            </Flex>
          ),
          description: [
            'core.modals.shareModal.descriptionContentModal',
            'core.modals.shareModal.descriptionContentModal2',
          ],
        }}
        onCallback={() => {
          setIsOpenContentModal(false)
        }}
        onDismissModal={() => {
          setIsOpenContentModal(false)
        }}
      />
      <Modal
        isOpen={isGuidelineOpen}
        maxWidth="50%"
        modalStyle={{ wrapperContainer: { overflow: 'scroll' } }}
        showFooter={false}
        onDismissModal={() => setIsGuidelineOpen(false)}
      >
        {guideline(handleGuidelineApproved)}
      </Modal>
      <Modal
        buttons={{
          deleteOrRemove: true,

          cancelText: <I18n text="actions.cancel" />,
          okText: <I18n text="actions.yesUnpublish" />,
        }}
        maxWidth="500px"
        hideClose
        isOpen={showUnpublishModal}
        content={{
          title: 'core.modals.unpublishTitle',
          description: 'core.unpublishCollection',
          modelName: 'collections',
          sentenceCase: true,
        }}
        onCallback={publishUnpublishCollection}
        onDismissModal={() => unpublishCollection()}
      />
    </Flex>
  )
}

myForm.defaultProps = {
  handleSubmit: noop,
  viewOnly: false,
}
myForm.propTypes = {
  dirty: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func,
  viewOnly: PropTypes.bool,
}
export default myForm
